<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="名称：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入名称" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" >新增</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="permissionTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="名称"  align="center" >
          <template #default="scope">{{scope.row.name}}</template>
        </el-table-column>
        <el-table-column label="路径"  align="center" >
          <template #default="scope">{{scope.row.path}}</template>
        </el-table-column>
        <el-table-column label="备注"  align="center" >
          <template #default="scope">{{scope.row.name}}</template>
        </el-table-column><el-table-column label="名称"  align="center" >
          <template #default="scope">{{scope.row.comment}}</template>
        </el-table-column>
        <el-table-column label="图标" align="center">
          <template #default="scope">{{scope.row.icon}}</template>
        </el-table-column>
        <el-table-column label="操作" width="250" align="center">
          <template #default="scope">
            <el-button size="mini" @click="handleUpdate(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEditFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="permissionModel"
               ref="permissionFrom"
               label-width="150px"
               size="small">
        <el-form-item label="父ID：">
          <el-input v-model="permissionModel.parent" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="英文名：">
          <el-input v-model="permissionModel.name" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="路径：">
          <el-input v-model="permissionModel.path" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="名称：" >
          <el-input v-model="permissionModel.comment" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="图标：" >
          <el-input v-model="permissionModel.icon" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-select v-model="permissionModel.type" filterable  style="width:80%">
            <el-option
                v-for="item in options"
                :key="item.key"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="permissionModel.sort" :min="1" style="width:80%"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('permissionFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

  </div>


</template>

<script>

import {batchDeletePermission, deletePermission, fetchList, updatePermission, createPermission} from "@/api/permission";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
};

const defaultPermission = {
  parent: null,
  name: null,
  path: null,
  comment: null,
  icon: null,
  type: null,
  sort: null,
};

export default {
  name: 'Permission',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      dialogVisible: false,
      isEditFlag: false,
      permissionModel: Object.assign({}, defaultPermission),
      options: [
        {
          value: '1',
          label: '菜单'
        },
        {
          value: '2',
          label: '功能'
        }
      ],
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePermission(row.id).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchDeletePermission(this.ids).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "批量删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;
      fetchList(this.listQuery).then(res => {
            if (res.code === '0') {
              this.listLoading = false;
              this.tableData = res.data.records;
              this.total = res.data.total;
            }
      })
    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.permissionModel = Object.assign({},defaultPermission);
    },
    //修改路由
    handleUpdate(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.permissionModel = Object.assign({},row);
    },
    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEditFlag) {
              updatePermission(this.permissionModel).then(res => {
                if (res.code === '0') {
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                  });
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });

            }else {
              createPermission(this.permissionModel).then(res => {
                if (res.code === '0') {
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });
            }
          });
        }
      });
    },
  }
}
</script>
